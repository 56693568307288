import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  // useMediaQuery,
  IconButton,
  Button,
  // Autocomplete,
  // useTheme,
  Typography,
  InputAdornment,
  CircularProgress,
  Paper,
  Alert,
} from "@mui/material";
import {
  registerForm,
  setAuthenticated,
  uploadKtpJp,
  setSnackbarOpen,
} from "src/redux/actions";
import localforage from "localforage";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  inputAlphabetOnly,
  inputNumberOnly,
} from "src/functions/inputNumberOnly";
import CustomInput from "./CustomInput";
import { loadImage } from "src/functions/utils";
import LoadableImage from "./LoadableImage";
import { version } from "src/configs/globalVariable";

function compareNumbers(input1, input2) {
  // Mengonversi angka menjadi string untuk mempermudah pemrosesan karakter
  const str1 = input1?.toString();
  const str2 = input2?.toString();

  // Menghitung jumlah karakter yang sama dan sejajar
  let matchingCount = 0;
  for (let i = 0; i < Math.min(str1.length, str2.length); i++) {
    if (str1[i] === str2[i]) {
      matchingCount++;
    }
  }

  // Menghitung persentase kesamaan
  const percentage = (matchingCount / Math.max(str1.length, str2.length)) * 100;
  return percentage;
}

const styleGrid = {
  p: 2,
  backgroundColor: "#ffff",
  m: "auto",
  borderRadius: "0.75rem",
  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
};

const Register = () => {
  // const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [form, setForm] = useState({});
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState("container");
  const [ocrPercentage, setOcrPercentage] = useState("0");
  const [ocrResult, setOcrResult] = useState("");
  const [pathKtp, setPathKtp] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  // const isScreen2000 = useMediaQuery("(min-width:2000px)");
  // const isScreen1400 = useMediaQuery("(min-width:1400px)");

  const baseURL =
    process.env.NODE_ENV !== "production"
      ? "http://localhost:5000/"
      : window.location.origin + "/";

  useEffect(() => {
    fetchRef();
  }, []);

  const fetchRef = async () => {
    const resEmail = await localforage.getItem("email");
    if (!resEmail) navigate("/");
    const copyForm = { ...form };
    copyForm["email"] = resEmail;
    setForm(copyForm);
    setLoading(false);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
    if (e.target.name === "identity_number") {
      const resCompare = compareNumbers(e.target.value, ocrResult);
      setOcrPercentage(resCompare || "0");
    }
  };

  const handleSubmit = async () => {
    setValidate(true);
    const con =
      !form.email ||
      !form.identity_number ||
      !form.fullname ||
      !form.password ||
      !form.password_conf ||
      !form.mobile_phone;
    if (con) return;

    if (form.password !== form.password_conf) return;

    form.identity_path = pathKtp;
    form.identity_ocr_result = ocrResult;
    form.identity_ocr_percentage = ocrPercentage;

    setLoading("buttonRegist");
    const resRegist = await registerForm({ data_personal: form });
    setLoading("");

    if (resRegist.code === 200) {
      setAuthenticated(resRegist.data.token);
      navigate(`/${version}/user/`);
    } else {
      setSnackbarOpen("error", resRegist.message);
    }
  };

  const handleUpload = async (files) => {
    if (files.length) {
      setLoading("uploadKtp");
      const resUpload = await uploadKtpJp(files[0]);
      if (typeof resUpload === "string") {
        setLoading("");
        setSnackbarOpen("error", resUpload);
        return;
      }
      setOcrResult(resUpload.data.nik);
      setPathKtp(resUpload.data?.raw?.path);
      setLoading("");
      if (resUpload.data.nik) {
        const resCompare = compareNumbers(
          form.identity_number,
          resUpload.data.nik
        );
        setOcrPercentage(resCompare || "0");
      } else {
        setOcrPercentage("0");
      }
    }
  };

  if (loading === "container")
    return (
      <Stack>
        <CircularProgress sx={{ m: "auto" }} />
      </Stack>
    );

  return (
    <Grid maxWidth={"md"} sx={styleGrid}>
      <Stack sx={{ width: "100%", p: 2, mt: 2 }}>
        <Typography sx={{ fontSize: "24px" }}>
          <b>Registrasi</b>
        </Typography>
        <Alert severity="warning" sx={{ mt: "1rem" }}>
          Mohon untuk dapat mengisi data-data awal yang diminta dengan VALID.
          Jika terdapat pemalsuan/kebohongan data, maka PAMA dapat
          menindaklanjuti sesuai dengan peraturan perundang-undangan yang
          berlaku serta peraturan perusahaan
        </Alert>
      </Stack>

      <Grid item container>
        <Grid item xs={12} sm={7} sx={{ p: 2 }}>
          <CustomInput label="Email" disabled value={form.email} />
          <CustomInput
            label="Nomor KTP (16 digit)"
            name="identity_number"
            onChange={handleChange}
            onKeyDown={inputNumberOnly}
            value={form.identity_number}
            error={validate && !form.identity_number}
            helperText={
              form?.identity_number?.length !== 16 ? (
                <span style={{ color: "red" }}>* Harus 16 Digit</span>
              ) : (
                ""
              )
            }
          />
          <CustomInput
            label="Nama Lengkap (Sesuai KTP)"
            name="fullname"
            onChange={handleChange}
            value={form.fullname}
            error={validate && !form.fullname}
            helperText={
              validate && !form.fullname && "* Nama lengkap harap diisi"
            }
            onKeyDown={inputAlphabetOnly}
          />
          <CustomInput
            label="Kata Sandi"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={form.password}
            error={validate && !form.password}
            helperText={
              validate && !form.password && "* Kata sandi harap diisi"
            }
            // helperText={
            //   validate && !form.password && "* Kata sandi harap diisi"
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            label="Konfirmasi Kata Sandi"
            name="password_conf"
            type={showPasswordConf ? "text" : "password"}
            onChange={handleChange}
            value={form.password_conf}
            error={validate && !form.password_conf}
            helperText={
              <Stack
                direction="column"
                sx={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.02em",
                }}
              >
                <Typography sx={{ fontSize: "inherit" }}>
                  {validate &&
                    !form.password_conf &&
                    "* Konfirmasi Kata sandi harap diisi"}
                </Typography>
                <Typography sx={{ fontSize: "inherit" }}>
                  {validate &&
                    form.password !== form.password_conf &&
                    "* Password tidak sesuai"}
                </Typography>
              </Stack>
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswordConf(!showPasswordConf)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPasswordConf ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            label="Nomor Handphone"
            name="mobile_phone"
            onChange={handleChange}
            value={form.mobile_phone}
            error={validate && !form.mobile_phone}
            helperText={
              validate && !form.mobile_phone && "* Nomor handphone harap diisi"
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: "1rem" }}>
            Upload Kartu Identitas
          </Typography>
          <Paper sx={{ width: "100%", height: "200px", mb: "1rem" }}>
            <LoadableImage
              src={pathKtp}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Paper>
          <Button
            variant="outlined"
            component="label"
            disabled={loading === "uploadKtp"}
            sx={{ mb: "1rem" }}
            startIcon={loading === "uploadKtp" ? <CircularProgress /> : null}
          >
            Upload Kartu Identitas
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleUpload(e.target.files)}
            />
          </Button>
          {pathKtp && (
            <Alert severity={ocrPercentage < 1 ? "error" : "success"}>
              {ocrPercentage >= 1 && <span>Upload KTP sudah sesuai</span>}
              <br />
              {ocrPercentage < 1 && (
                <span style={{ fontSize: "12px" }}>
                  Hasil upload ktp belum sesuai, silahkan upload kembali
                </span>
              )}
            </Alert>
          )}
        </Grid>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        justifyContent={"end"}
        sx={{ my: "1rem", pr: 2, width: "100%" }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
            localforage.removeItem("email");
          }}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            form?.identity_number?.length !== 16 ||
            loading === "buttonRegist" ||
            ocrPercentage < 1
          }
        >
          Kirim
        </Button>
      </Stack>
    </Grid>
  );
};

export default Register;
