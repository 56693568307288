import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
  Button,
  Paper,
  TextField,
  IconButton,
  styled,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Switch,
} from "@mui/material";
import RichEditor from "src/functions/editor";
import { getIdYoutubeVideo } from "src/functions/getIdYoutubeVideo";
// import noImage from "src/assets/img/no-image.jpg";
import {
  Add,
  ArrowForwardIosSharp,
  Clear,
  Delete,
  ExpandMore,
  Videocam,
  YouTube,
  Photo,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { listElements } from "./library";
import "../../../App.css";
import CustomInput from "src/components/CustomInput";
import flagInd from "src/assets/img/flagind.png";
import flagEn from "src/assets/img/flagEn.png";
import { version } from "src/configs/globalVariable";

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { color: `${theme.palette.text.primary}` },
}));

export const listEditElements = (
  el,
  form,
  handleChange,
  handleChangeFile,
  handleNavigation,
  loading,
  validate,
  setOpenConfirm
) => {
  switch (el.variant) {
    case "element0":
      return <TextControl form={form} handleChange={handleChange} />;
    case "element1": // youtube video
      return (
        <Media
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          loading={loading}
          validate={validate}
        />
      );
    case "element2": // common video
      return (
        <Media
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          loading={loading}
          validate={validate}
        />
      );
    case "element3": // image
      return (
        <Media
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          loading={loading}
          validate={validate}
        />
      );
    case "element4":
      return <EditButton form={form} handleChange={handleChange} />;
    // case "element5":
    //   return <Typography {...data.props}>{data}</Typography>;
    case "element10":
      return (
        <SimpleScrollItemEdit
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          handleNavigation={handleNavigation}
          loading={loading}
          validate={validate}
        />
      );
    case "element11":
      return (
        <NavigationBarEdit
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          handleNavigation={handleNavigation}
          loading={loading}
          validate={validate}
          setOpenConfirm={setOpenConfirm}
        />
      );
    case "element12":
      return (
        <FooterEdit
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          handleNavigation={handleNavigation}
          loading={loading}
          validate={validate}
          setOpenConfirm={setOpenConfirm}
        />
      );
    case "element13":
      return <RunningTextEdit form={form} handleChange={handleChange} />;
    case "element61":
      return (
        <FAQEdit
          form={form}
          handleChange={handleChange}
          handleNavigation={handleNavigation}
        />
      );
    default:
      return <></>;
  }
};

const TextControl = ({ form, handleChange }) => {
  const [code, setCode] = useState("id");
  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", mb: "1rem", mt: "1rem" }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "600" }}
        >
          Multi Bahasa:
        </Typography>
        <Stack
          justifyContent="space-evenly"
          direction="row"
          sx={{ width: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: code === "id" && "#FAFAFA",
              border: code === "id" && "1px solid lightgrey",
            }}
            onClick={() => setCode("id")}
          >
            <img
              alt="flag"
              src={flagInd}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary">
              Bahasa
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: code === "en" && "#FAFAFA",
              border: code === "en" && "1px solid lightgrey",
            }}
            onClick={() => setCode("en")}
          >
            <img
              alt="flag"
              src={flagEn}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary" sx={{ ml: 0.25 }}>
              English
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {code === "id" && (
        <RichEditor
          onChange={(e) => handleChange("html", e)}
          value={form.html}
        />
      )}
      {code === "en" && (
        <RichEditor
          onChange={(e) => handleChange("htmlEn", e)}
          value={form.htmlEn}
        />
      )}
    </Stack>
  );
};

export const Media = ({
  form,
  handleChange,
  handleChangeFile,
  loading,
  validate,
  bg,
  aspectRatio,
  state,
  detailKey,
}) => {
  const theme = useTheme();
  const copyForm = { ...form };
  if (!state) {
    copyForm.props = {
      maxHeight: !form.elCn && "10rem",
      height: "auto",
      maxWidth: "100%",
      margin: "auto",
      position: "unset",
      aspectRatio: form.props?.aspectRatio,
    };
  }

  const placeholder =
    form?.variant === "element1"
      ? "Type link of youtube video in here"
      : "Upload or type link of the image in here";

  return (
    <>
      {!bg &&
        !state && ( // selain background
          <Stack direction="row" justifyContent="center" alignItems="center">
            {["element1", "element2", "element3"].map((v, i) => (
              <IconButton
                key={v}
                sx={{
                  mx: 1,
                  my: 2,
                  p: 2,
                  width: "40px",
                  height: "40px",
                  boxShadow:
                    form.variant === v &&
                    `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
                  backgroundColor: form.variant === v && "#f0f8ff",

                  "&:hover": {
                    boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
                    backgroundColor: "#f0f8ff",
                  },
                }}
              >
                {/* {i === 0 && <i class="fi fi-brands-youtube"></i>} */}
                {/* {i === 1 && <i class="fi fi-sr-video-camera-alt"></i>} */}
                {/* {i === 2 && <i class="fi fi-br-picture"></i>} */}
                {i === 0 && <YouTube />}
                {i === 1 && <Videocam />}
                {i === 2 && <Photo />}
              </IconButton>
            ))}
          </Stack>
        )}

      {form.variant === "element2" && (
        <Video
          form={form}
          handleChange={handleChange}
          handleChangeFile={handleChangeFile}
          loading={loading}
          validate={validate}
          detailKey={detailKey}
        />
      )}

      {form.variant !== "element2" && (
        <Stack direction="column" sx={{ width: "100%", display: "flex" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%", mt: 1 }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              {state === "logo" && form.variant === "element3"
                ? "Logo"
                : form.variant === "element1"
                ? "Youtube Video"
                : "Image"}
            </Typography>
            {form.variant !== "element1" && (
              <ButtonUploadMedia
                props={{
                  name: "src",
                  variant: form.variant,
                  loading: loading,
                  onInput: (e) => handleChangeFile(e.target.name, e, detailKey),
                }}
              />
            )}
          </Stack>

          {validate && (
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 2, mt: 0, textAlign: "center" }}
            >
              * {validate}
            </Typography>
          )}

          {form.src && !state && listElements(copyForm)}
          <Input
            props={{
              // multiline
              // rows={4}
              placeholder: placeholder,
              name: "src",
              value: form.src,
              onChange: (e) => {
                let val;
                if (form.variant === "element1") {
                  const id = getIdYoutubeVideo(e.target.value);
                  val = `https://www.youtube.com/embed/${id}`;
                  handleChange(e.target.name, val, "link");
                } else {
                  val = e.target.value;
                  handleChange(e.target.name, val, undefined, detailKey);
                }
              },
            }}
          />
          {!bg && state !== "logo" && form.variant === "element3" && (
            <Stack direction="column" sx={{ width: "100%", mt: 1 }}>
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ mb: 1, fontWeight: "bold" }}
              >
                Action URL
              </Typography>
              <Input
                props={{
                  placeholder: "URL",
                  name: "url",
                  value: form.url,
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      undefined,
                      detailKey
                    ),
                }}
              />
            </Stack>
          )}
          {!state && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%", my: 1 }}
            >
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ fontWeight: "bold" }}
              >
                Aspect Ratio
              </Typography>
              <Input
                props={{
                  width: "7rem",
                  placeholder: "eg: 16/9",
                  name: "aspectRatio",
                  value: !bg ? form.props?.aspectRatio : aspectRatio,
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      !bg ? "props-style" : undefined,
                      !bg ? ["props"] : detailKey
                    ),
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};

export const Video = ({
  form,
  handleChange,
  handleChangeFile,
  loading,
  validate,
  aspectRatio,
  detailKey,
  bg,
}) => {
  const copyForm = { ...form };
  copyForm.props = {
    maxHeight: "10rem",
    width: "auto",
    maxWidth: "100%",
    margin: "auto",
  };

  return (
    <Stack direction="column" sx={{ width: "100%", display: "flex" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: 1 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Video
        </Typography>

        <ButtonUploadMedia
          props={{
            name: "src",
            variant: form.variant,
            loading: loading,
            onInput: (e) => handleChangeFile(e.target.name, e),
          }}
        />
      </Stack>

      {validate && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 2, mt: 0, textAlign: "center" }}
        >
          * {validate}
        </Typography>
      )}

      {form.src && listElements(copyForm)}
      <Input
        props={{
          placeholder: "Upload or type link of the video in here",
          name: "src",
          value: form.src,
          onChange: (e) => handleChange(e.target.name, e.target.value),
        }}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: 1 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Thumbnail Image
        </Typography>
        <ButtonUploadMedia
          props={{
            name: "poster",
            variant: "element3",
            loading: loading,
            onInput: (e) => handleChangeFile(e.target.name, e),
          }}
        />
      </Stack>
      <Typography
        variant="caption"
        color="text.tertiary"
        sx={{ mb: 1, mt: 0, fontStyle: "italic" }}
      >
        * This attribute gives the video an image to show while the video is
        loading.
      </Typography>
      <Input
        props={{
          placeholder: "Upload or type link of the thumbnail image in here",
          name: "poster",
          value: form.poster,
          onChange: (e) => handleChange(e.target.name, e.target.value),
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: 1 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "bold" }}
        >
          Aspect Ratio
        </Typography>
        <Input
          props={{
            width: "7rem",
            placeholder: "eg: 16/9",
            name: "aspectRatio",
            value: !bg ? form.props.aspectRatio : aspectRatio,
            onChange: (e) =>
              handleChange(
                e.target.name,
                e.target.value,
                !bg ? "props-style" : undefined,
                !bg ? ["props"] : detailKey
              ),
          }}
        />
      </Stack>
    </Stack>
  );
};

const EditButton = ({ form, handleChange }) => {
  const [code, setCode] = useState("id");
  return (
    <>
      <Stack direction="column" sx={{ width: "100%", my: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: "100%", mb: "0.5rem", mt: "0.5rem" }}
        >
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "600" }}
          >
            Button Name
          </Typography>
          <Stack
            justifyContent="space-evenly"
            direction="row"
            sx={{ width: "70%" }}
          >
            <Button
              sx={{
                backgroundColor: code === "id" && "#FAFAFA",
                border: code === "id" && "1px solid lightgrey",
              }}
              onClick={() => setCode("id")}
            >
              <img
                alt="flag"
                src={flagInd}
                style={{ width: "2rem", height: "1.5rem" }}
              />
              <Typography variant="body2" color="text.primary">
                Bahasa
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: code === "en" && "#FAFAFA",
                border: code === "en" && "1px solid lightgrey",
              }}
              onClick={() => setCode("en")}
            >
              <img
                alt="flag"
                src={flagEn}
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
              <Typography
                variant="body2"
                color="text.primary"
                sx={{ ml: 0.25 }}
              >
                English
              </Typography>
            </Button>
          </Stack>
        </Stack>
        {code === "id" && (
          <Input
            props={{
              placeholder: "Content of button",
              name: "name",
              value: form.name,
              onChange: (e) => handleChange(e.target.name, e.target.value),
            }}
          />
        )}
        {code === "en" && (
          <Input
            props={{
              placeholder: "Content of button",
              name: "nameEn",
              value: form.nameEn,
              onChange: (e) => handleChange(e.target.name, e.target.value),
            }}
          />
        )}
      </Stack>

      <Stack direction="column" sx={{ width: "100%", my: 1 }}>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ mb: 1, fontWeight: "bold" }}
        >
          Navigasi
        </Typography>
        <Input
          props={{
            placeholder: "eg: https://www.google.com",
            name: "url",
            value: form.url,
            onChange: (e) => handleChange(e.target.name, e.target.value),
          }}
        />
      </Stack>
    </>
  );
};

const SimpleScrollItemEdit = ({
  form,
  handleChange,
  handleChangeFile,
  handleNavigation,
  loading,
  validate,
}) => {
  const [cardIdx, setCardIdx] = useState(false);
  const [idx, setIdx] = useState(0);
  const [code, setCode] = useState("id");
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "bold" }}
        >
          Card
        </Typography>
        <IconButton
          onClick={() =>
            handleNavigation(
              "add simple-scroll-card",
              ["children"],
              ["id", "src", "text", "textEn"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>
      <Grid container>
        {form.children.map((cardImage, i) => (
          <Grid item key={cardImage.id} xs={6} sx={{ position: "relative" }}>
            <Paper
              sx={{
                p: 2,
                border: "none",
                boxShadow: "none",
                position: "relative",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                setCardIdx(cardIdx === i ? false : i);
                setIdx(i);
              }}
            >
              {listElements({
                ...cardImage,
                elCn: "",
                props: {
                  width: "100%",
                  aspectRatio: "16/9",
                  borderRadius: "0.5rem",
                  objectFit: "cover",
                },
              })}
              {cardIdx === i && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    p: 2,
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                    backgroundColor: "rgb(255,255,255,0.4)",
                  }}
                ></Box>
              )}
            </Paper>
            <IconButton
              sx={{
                position: "absolute",
                top: -10,
                right: 5,
                zIndex: 3,
                backgroundColor: "#f8f8f8",
              }}
              onClick={() => {
                setCardIdx(false);
                handleNavigation("delete simple-scroll-card", ["children"], i);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Grid>
        ))}
      </Grid>
      {cardIdx !== false && cardIdx === idx && (
        <Stack direction="column" sx={{ width: "100%", my: 1 }}>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ my: 1, mt: 4, fontWeight: "bold" }}
          >
            Detail Card
          </Typography>
          <Media
            form={form.children[cardIdx]}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            state="simple"
            detailKey={["children", cardIdx]}
          />
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%", mb: "1rem", mt: "1rem" }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "600" }}
            >
              Text Multi Bahasa:
            </Typography>
            <Stack
              justifyContent="space-evenly"
              direction="row"
              sx={{ width: "70%" }}
            >
              <Button
                sx={{
                  backgroundColor: code === "id" && "#FAFAFA",
                  border: code === "id" && "1px solid lightgrey",
                }}
                onClick={() => setCode("id")}
              >
                <img
                  alt="flag"
                  src={flagInd}
                  style={{ width: "2rem", height: "1.5rem" }}
                />
                <Typography variant="body2" color="text.primary">
                  Bahasa
                </Typography>
              </Button>
              <Button
                sx={{
                  backgroundColor: code === "en" && "#FAFAFA",
                  border: code === "en" && "1px solid lightgrey",
                }}
                onClick={() => setCode("en")}
              >
                <img
                  alt="flag"
                  src={flagEn}
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ ml: 0.25 }}
                >
                  English
                </Typography>
              </Button>
            </Stack>
          </Stack>
          {code === "id" && (
            <Input
              props={{
                placeholder: "Text",
                name: "text",
                value: form.children[cardIdx]?.text,
                onChange: (e) => {
                  // if (e.target.value.match("^[0-9]. *$"))
                  handleChange(e.target.name, e.target.value, undefined, [
                    "children",
                    cardIdx,
                  ]);
                },
              }}
            />
          )}
          {code === "en" && (
            <Input
              props={{
                placeholder: "Text",
                name: "textEn",
                value: form.children[cardIdx]?.textEn,
                onChange: (e) => {
                  // if (e.target.value.match("^[0-9]. *$"))
                  handleChange(e.target.name, e.target.value, undefined, [
                    "children",
                    cardIdx,
                  ]);
                },
              }}
            />
          )}
        </Stack>
      )}
    </>
  );
};

export const Align = ({ form, handleChange, detailKey }) => {
  const data = [
    { key: "start", value: "flex-start" },
    { key: "center", value: "center" },
    { key: "end", value: "flex-end" },
  ];
  // const dataDir = ["row", "column"];
  return (
    <>
      {/* <Stack direction="column" sx={{ width: "100%", my: 1 }}>
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ my: 1, mt: 1, fontWeight: "bold" }}
        >
          Direction
        </Typography>
        <Dropdown
          name="flexDirection"
          value={form?.flexDirection}
          data={dataDir}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value, undefined, detailKey);
          }}
        />
      </Stack> */}
      <Stack direction="column" sx={{ width: "100%", my: 1 }}>
        <Typography
          variant="caption"
          color="text.primary"
          sx={{ my: 0.5, fontWeight: "bold" }}
        >
          Horizontal
        </Typography>
        <Dropdown
          name="justifyContent"
          value={form?.justifyContent}
          data={data}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value, undefined, detailKey);
          }}
        />
      </Stack>
      <Stack direction="column" sx={{ width: "100%", my: 1 }}>
        <Typography
          variant="caption"
          color="text.primary"
          sx={{ my: 0.5, fontWeight: "bold" }}
        >
          Vertical
        </Typography>
        <Dropdown
          name="alignItems"
          value={form?.alignItems}
          data={data}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value, undefined, detailKey);
          }}
        />
      </Stack>
    </>
  );
};

export const Spacing = ({ state, form, handleChange, detailKey }) => {
  const arrMargin = [
    { key: "mt", alias: "top" },
    { key: "mb", alias: "bottom" },
    { key: "ml", alias: "left" },
    { key: "mr", alias: "right" },
  ];
  const arrPadding = [
    { key: "pt", alias: "top" },
    { key: "pb", alias: "bottom" },
    { key: "pl", alias: "left" },
    { key: "pr", alias: "right" },
  ];
  const arr = state === "margin" ? arrMargin : arrPadding;
  return (
    <>
      <Grid container spacing={2}>
        {arr.map((x, i) => (
          <Grid item xs={3} key={i}>
            <Input
              props={{
                type: "number",
                placeholder: x.alias,
                name: x.key,
                value: form[x.key] ? form[x.key]?.split("rem")[0] : "",
                onChange: (e) => {
                  // if (e.target.value.match("^[0-9]. *$"))
                  handleChange(
                    e.target.name,
                    e.target.value,
                    "props-style-spacing",
                    detailKey
                  );
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const RunningTextEdit = ({ form, handleChange }) => {
  const [code, setCode] = useState("id");
  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", mb: "1rem", mt: "1rem" }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "600" }}
        >
          Multi Bahasa:
        </Typography>
        <Stack
          justifyContent="space-evenly"
          direction="row"
          sx={{ width: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: code === "id" && "#FAFAFA",
              border: code === "id" && "1px solid lightgrey",
            }}
            onClick={() => setCode("id")}
          >
            <img
              alt="flag"
              src={flagInd}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary">
              Bahasa
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: code === "en" && "#FAFAFA",
              border: code === "en" && "1px solid lightgrey",
            }}
            onClick={() => setCode("en")}
          >
            <img
              alt="flag"
              src={flagEn}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary" sx={{ ml: 0.25 }}>
              English
            </Typography>
          </Button>
        </Stack>
      </Stack>
      {code === "id" && (
        <Input
          props={{
            placeholder: "Text",
            name: "text",
            value: form?.text,
            onChange: (e) => {
              // if (e.target.value.match("^[0-9]. *$"))
              handleChange(e.target.name, e.target.value);
            },
          }}
        />
      )}
      {code === "en" && (
        <Input
          props={{
            placeholder: "Text",
            name: "textEn",
            value: form?.textEn,
            onChange: (e) => {
              // if (e.target.value.match("^[0-9]. *$"))
              handleChange(e.target.name, e.target.value);
            },
          }}
        />
      )}
    </Stack>
  );
};

const NavigationBarEdit = ({
  form,
  handleChange,
  handleChangeFile,
  handleNavigation,
  loading,
  validate,
  setOpenConfirm,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [code, setCode] = useState("id");
  return (
    <>
      <Media
        form={form.children[0]}
        handleChange={handleChange}
        handleChangeFile={handleChangeFile}
        loading={loading}
        validate={validate}
        detailKey={["children", 0]}
        state="logo"
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 1, mt: 0.5 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "bold" }}
        >
          Height Logo
        </Typography>
        <Input
          props={{
            type: "number",
            width: "7rem",
            placeholder: "eg: 5",
            name: "height",
            value: form.children[0].props.height
              ? form.children[0].props.height?.split("rem")[0]
              : "",
            onChange: (e) => {
              handleChange(
                e.target.name,
                e.target.value,
                "props-style-spacing",
                ["children", 0, "props"]
              );
            },
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", mb: "0.5rem", mt: "1.5rem" }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "600" }}
        >
          Multi Bahasa:
        </Typography>
        <Stack
          justifyContent="space-evenly"
          direction="row"
          sx={{ width: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: code === "id" && "#FAFAFA",
              border: code === "id" && "1px solid lightgrey",
            }}
            onClick={() => setCode("id")}
          >
            <img
              alt="flag"
              src={flagInd}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary">
              Bahasa
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: code === "en" && "#FAFAFA",
              border: code === "en" && "1px solid lightgrey",
            }}
            onClick={() => setCode("en")}
          >
            <img
              alt="flag"
              src={flagEn}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary" sx={{ ml: 0.25 }}>
              English
            </Typography>
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav1" ? false : "nav1")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav1" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Main Navigation
          </Typography>
        </Stack>

        <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "mainLink"],
              ["text", "textEn", "url", "visibility"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {isExpanded === "nav1" && (
        <>
          {form.children[0].mainLink.map((link, i) => {
            const shortDetailKey = ["children", 0, "mainLink", i];
            return (
              <GroupLink
                key={i}
                link={link}
                handleChange={handleChange}
                i={i}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                setOpenConfirm={setOpenConfirm}
                code={code}
              />
            );
          })}
        </>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav2" ? false : "nav2")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav2" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Other Link
          </Typography>
        </Stack>

        <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "link"],
              ["text", "textEn", "url", "visibility"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {isExpanded === "nav2" && (
        <>
          {form.children[0].link.map((link, z) => {
            const shortDetailKey = ["children", 0, "link", z];
            return (
              <GroupLink
                key={z}
                link={link}
                handleChange={handleChange}
                i={z}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                setOpenConfirm={setOpenConfirm}
                code={code}
              />
            );
          })}
        </>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav3" ? false : "nav3")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav3" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Dashboard Link
          </Typography>
        </Stack>

        <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "dashboard"],
              ["text", "textEn", "url", "visibility"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {isExpanded === "nav3" && (
        <>
          {form.children[0].dashboard.map((link, w) => {
            const shortDetailKey = ["children", 0, "dashboard", w];
            return (
              <GroupLink
                key={w}
                defaultMode={true}
                link={link}
                handleChange={handleChange}
                i={w}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                code={code}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const FooterEdit = ({
  form,
  handleChange,
  handleChangeFile,
  handleNavigation,
  loading,
  validate,
  setOpenConfirm,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [code, setCode] = useState("id");
  return (
    <>
      <Media
        form={form.children[0]}
        handleChange={handleChange}
        handleChangeFile={handleChangeFile}
        loading={loading}
        validate={validate}
        detailKey={["children", 0]}
        state="logo"
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "bold" }}
        >
          Address
        </Typography>
      </Stack>
      <Input
        props={{
          placeholder: "Address",
          name: "address",
          value: form.children[0].address,
          onChange: (e) => {
            handleChange(e.target.name, e.target.value, undefined, [
              "children",
              0,
            ]);
          },
        }}
      />

      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", mb: "0.5rem", mt: "1.5rem" }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "600" }}
        >
          Multi Bahasa:
        </Typography>
        <Stack
          justifyContent="space-evenly"
          direction="row"
          sx={{ width: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: code === "id" && "#FAFAFA",
              border: code === "id" && "1px solid lightgrey",
            }}
            onClick={() => setCode("id")}
          >
            <img
              alt="flag"
              src={flagInd}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary">
              Bahasa
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: code === "en" && "#FAFAFA",
              border: code === "en" && "1px solid lightgrey",
            }}
            onClick={() => setCode("en")}
          >
            <img
              alt="flag"
              src={flagEn}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary" sx={{ ml: 0.25 }}>
              English
            </Typography>
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav1" ? false : "nav1")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav1" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Main Navigation
          </Typography>
        </Stack>

        <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "mainLink"],
              ["text", "textEn", "url", "visibility"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {isExpanded === "nav1" && (
        <>
          {form.children[0].mainLink.map((link, i) => {
            const shortDetailKey = ["children", 0, "mainLink", i];
            return (
              <GroupLink
                key={i}
                link={link}
                handleChange={handleChange}
                i={i}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                setOpenConfirm={setOpenConfirm}
                code={code}
              />
            );
          })}
        </>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav2" ? false : "nav2")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav2" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Other Link
          </Typography>
        </Stack>

        <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "link"],
              ["text", "textEn", "url", "visibility"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {isExpanded === "nav2" && (
        <>
          {form.children[0].link.map((link, z) => {
            const shortDetailKey = ["children", 0, "link", z];
            return (
              <GroupLink
                key={z}
                link={link}
                handleChange={handleChange}
                i={z}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                setOpenConfirm={setOpenConfirm}
                code={code}
              />
            );
          })}
        </>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ mr: 1 }}
            onClick={() =>
              setIsExpanded(isExpanded === "nav3" ? false : "nav3")
            }
          >
            <ArrowForwardIosSharp
              sx={{
                width: "1rem",
                transition: "all linear .2s",
                transform:
                  isExpanded === "nav3" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            Social Media
          </Typography>
        </Stack>

        {/* <IconButton
          onClick={() =>
            handleNavigation(
              "add",
              ["children", 0, "socialMedia"],
              ["media", "content"]
            )
          }
        >
          <Add />
        </IconButton> */}
      </Stack>

      {isExpanded === "nav3" && (
        <>
          {form.children[0].socialMedia.map((link, w) => {
            const shortDetailKey = ["children", 0, "socialMedia", w];
            return (
              <GroupLink
                key={w}
                link={link}
                handleChange={handleChange}
                i={w}
                shortDetailKey={shortDetailKey}
                handleNavigation={handleNavigation}
                media={true}
                code={code}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const GroupLink = ({
  link,
  handleChange,
  i,
  shortDetailKey,
  handleNavigation,
  media,
  defaultMode,
  setOpenConfirm,
  code,
}) => {
  const dataPage = [
    { key: "Home", value: `/${version}/user` },
    { key: "Vacancy", value: `/${version}/user/vacancy` },
    { key: "Product", value: `/${version}/user/product` },
    { key: "FAQ", value: `/${version}/user/FAQ` },
    { key: "Embed Form", value: `/${version}/user/embed-form` },
    { key: "Other", value: "Other" },
  ];

  const dataDefaultPage = [
    {
      key: "Dashboard-My-Career",
      value: `/${version}/user/dashboard/my-career`,
    },
    {
      key: "Dashboard-My-Wishlist",
      value: `/${version}/user/dashboard/my-wishlist`,
    },
    { key: "Profile", value: `/${version}/user/profile` },
  ];

  const theme = useTheme();

  return (
    <>
      <Stack
        key={i}
        sx={{
          px: 2,
          pt: 2,
          backgroundColor: "#f8f8f8",
          my: 2,
          borderRadius: "1rem",
          position: "relative",
        }}
      >
        {!media && (
          <>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "bold" }}
            >
              {link.children && link.children.length > 0
                ? "Title Group"
                : "Text"}
            </Typography>
            {code === "id" && (
              <Input
                props={{
                  placeholder: link.isGroup ? "Title Group" : "Navigation text",
                  name: "text",
                  value: link.text,
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      undefined,
                      shortDetailKey
                    ),
                }}
              />
            )}
            {code === "en" && (
              <Input
                props={{
                  placeholder: link.isGroup ? "Title Group" : "Navigation text",
                  name: "textEn",
                  value: link.textEn,
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      undefined,
                      shortDetailKey
                    ),
                }}
              />
            )}

            {link.url.includes("/user/embed-form") && (
              <>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ fontWeight: "bold" }}
                >
                  Form Facade URL
                </Typography>
                <Input
                  props={{
                    placeholder: "Form Facade URL",
                    name: "urlForm",
                    value: link.urlForm,
                    onChange: (e) =>
                      handleChange(
                        e.target.name,
                        e.target.value,
                        undefined,
                        shortDetailKey
                      ),
                  }}
                />
              </>
            )}

            <Stack
              sx={{ px: 2, mb: 2, color: `${theme.palette.text.primary}` }}
            >
              {!link.isGroup && (
                <Stack direction="row" alignItems="center">
                  <Typography color="inherit" sx={{ width: "70%" }}>
                    This link navigate to
                  </Typography>
                  <Stack sx={{ width: "30%" }}>
                    <Dropdown
                      name="url"
                      value={link.url}
                      data={defaultMode ? dataDefaultPage : dataPage}
                      // disabled={defaultMode ? true : false}
                      onChange={(e) => {
                        if (code === "id") {
                          if (e.target.value === "Other") {
                            handleChange(
                              "isExternal",
                              true,
                              undefined,
                              shortDetailKey
                            );
                            handleChange(
                              e.target.name,
                              "",
                              undefined,
                              shortDetailKey
                            );
                          } else {
                            handleChange(
                              "isExternal",
                              false,
                              undefined,
                              shortDetailKey
                            );
                            handleChange(
                              e.target.name,
                              e.target.value,
                              undefined,
                              shortDetailKey
                            );
                          }
                        }
                      }}
                    />
                  </Stack>
                </Stack>
              )}
              {!link.isGroup && link.isExternal && (
                <Stack sx={{ mb: 0.5 }}>
                  <Input
                    props={{
                      placeholder: "Navigation url",
                      name: "url",
                      value: link.url,
                      onChange: (e) =>
                        handleChange(
                          e.target.name,
                          e.target.value,
                          undefined,
                          shortDetailKey
                        ),
                    }}
                  />
                </Stack>
              )}
              {!defaultMode && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomFormControlLabel
                    checked={link.isGroup}
                    name="isGroup"
                    control={<Switch color="primary" />}
                    onChange={(e) => {
                      if (code === "id") {
                        handleChange(
                          e.target.name,
                          e.target.checked,
                          undefined,
                          shortDetailKey
                        );

                        if (e.target.checked && !link.idGroup) {
                          handleChange(
                            "idGroup",
                            Date.now(),
                            undefined,
                            shortDetailKey
                          );
                          handleChange(
                            "isExternal",
                            false,
                            undefined,
                            shortDetailKey
                          );
                        }
                      }
                    }}
                    label="Custom Page"
                    labelPlacement="end"
                  />

                  {link.isGroup && (
                    <IconButton
                      onClick={() => {
                        if (code === "id") {
                          if (!link.children) {
                            // children deleted when toggle isGroup is off
                            // setCopyFormByDetailKey
                            handleChange(
                              "children",
                              [],
                              undefined,
                              shortDetailKey
                            );
                          }
                          setTimeout(() => {
                            handleNavigation(
                              "add",
                              [...shortDetailKey, "children"],
                              ["text", "textEn", "id", "visibility"]
                            );
                          }, 10);
                        }
                      }}
                    >
                      <Add />
                    </IconButton>
                  )}
                </Stack>
              )}
              {link.isGroup && link.children && link.children.length > 0 && (
                <Stack sx={{ my: 1 }}>
                  {link.children.map((x, z) => (
                    <Stack
                      key={z}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Clear
                        sx={{
                          mr: 1,
                          width: "1.25rem",
                          color: "lightgrey",
                          "&:hover": { color: "inherit", cursor: "pointer" },
                        }}
                        // handleNavigation(
                        //   "delete",
                        //   [...shortDetailKey, "children"],
                        //   z
                        // )
                        onClick={() => {
                          if (code === "id") {
                            setOpenConfirm({
                              type: "navigation and page",
                              data: x,
                              shortDetailKey: [...shortDetailKey, "children"],
                              i: z,
                            });
                          }
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          if (code === "id") {
                            handleChange(
                              "visibility",
                              !x.visibility,
                              undefined,
                              [...shortDetailKey, "children", z]
                            );
                          }
                        }}
                      >
                        {x.visibility && (
                          <Visibility sx={{ color: "#98A2D6" }} />
                        )}
                        {!x.visibility && (
                          <VisibilityOff sx={{ color: "lightgrey" }} />
                        )}
                      </IconButton>
                      {code === "id" && (
                        <Input
                          props={{
                            my: 0.5,
                            placeholder: "Sub Group Text",
                            name: "text",
                            value: x.text,
                            onChange: (e) =>
                              handleChange(
                                e.target.name,
                                e.target.value,
                                undefined,
                                [...shortDetailKey, "children", z]
                              ),
                          }}
                        />
                      )}

                      {code === "en" && (
                        <Input
                          props={{
                            my: 0.5,
                            placeholder: "Sub Group Text",
                            name: "textEn",
                            value: x.textEn,
                            onChange: (e) =>
                              handleChange(
                                e.target.name,
                                e.target.value,
                                undefined,
                                [...shortDetailKey, "children", z]
                              ),
                          }}
                        />
                      )}
                    </Stack>
                  ))}
                </Stack>
              )}
            </Stack>
          </>
        )}

        {media && (
          <>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "bold" }}
            >
              {link.media}
            </Typography>
            <Input
              props={{
                placeholder: "url",
                name: "content",
                value: link.content,
                onChange: (e) =>
                  handleChange(
                    e.target.name,
                    e.target.value,
                    undefined,
                    shortDetailKey
                  ),
              }}
            />
            {link.media === "Floating WhatsApp Button" && (
              <Input
                props={{
                  placeholder: "text",
                  name: "text",
                  value: link.text,
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      undefined,
                      shortDetailKey
                    ),
                }}
              />
            )}
          </>
        )}

        {!media && ( // !media && !defaultMode
          <IconButton
            sx={{
              position: "absolute",
              top: -15,
              right: 5,
              backgroundColor: "#f8f8f8",
            }}
            onClick={() =>
              setOpenConfirm({
                type: "navigation and page",
                data: link,
                shortDetailKey: shortDetailKey.slice(0, -1),
                i,
              })
            }
          >
            <Delete color="error" />
          </IconButton>
        )}
        {((media && link.visibility) || !media) && ( // !media && !defaultMode
          <IconButton
            sx={{
              position: "absolute",
              top: -15,
              right: media && link.visibility ? 0 : 55,
              backgroundColor: "#f8f8f8",
            }}
            onClick={() =>
              handleChange(
                "visibility",
                !link.visibility,
                undefined,
                shortDetailKey
              )
            }
          >
            {link.visibility && <Visibility sx={{ color: "#0000ff" }} />}
            {!link.visibility && <VisibilityOff />}
          </IconButton>
        )}
      </Stack>
    </>
  );
};

const FAQEdit = ({ form, handleChange, handleNavigation }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [code, setCode] = useState("id");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "100%", mt: "1rem" }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "600" }}
        >
          Multi Bahasa:
        </Typography>
        <Stack
          justifyContent="space-evenly"
          direction="row"
          sx={{ width: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: code === "id" && "#FAFAFA",
              border: code === "id" && "1px solid lightgrey",
            }}
            onClick={() => setCode("id")}
          >
            <img
              alt="flag"
              src={flagInd}
              style={{ width: "2rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary">
              Bahasa
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: code === "en" && "#FAFAFA",
              border: code === "en" && "1px solid lightgrey",
            }}
            onClick={() => setCode("en")}
          >
            <img
              alt="flag"
              src={flagEn}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <Typography variant="body2" color="text.primary" sx={{ ml: 0.25 }}>
              English
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", mt: 2 }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Question - Answer
        </Typography>
        <IconButton
          sx={{ mb: 2 }}
          onClick={() =>
            handleNavigation(
              "add",
              ["children"],
              ["question", "questionEn", "answer", "answerEn"]
            )
          }
        >
          <Add />
        </IconButton>
      </Stack>

      {form.children.map((v, i) => (
        <Accordion
          key={i}
          expanded={expanded === `panel${i + 1}`}
          onChange={handleChangeAccordion(`panel${i + 1}`)}
          sx={{
            borderRadius: "0.5rem",
            // "&:not(:last-child)": {
            borderBottom: "none",
            // },
          }}
          square
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${i + 1}`}
          >
            <Typography variant="body2">
              {code === "en"
                ? v.questionEn || "Undefined Question"
                : v.question || "Undefined Question"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "bold" }}
            >
              Question
            </Typography>
            {code === "id" && (
              <Input
                props={{
                  placeholder: "Pertanyaan",
                  name: "question",
                  value: v.question,
                  onChange: (e) => {
                    handleChange(e.target.name, e.target.value, undefined, [
                      "children",
                      i,
                    ]);
                  },
                  InputProps: {
                    rows: 2,
                    multiline: true,
                    inputComponent: "textarea",
                  },
                }}
              />
            )}
            {code === "en" && (
              <Input
                props={{
                  placeholder: "Question",
                  name: "questionEn",
                  value: v.questionEn,
                  onChange: (e) => {
                    handleChange(e.target.name, e.target.value, undefined, [
                      "children",
                      i,
                    ]);
                  },
                  InputProps: {
                    rows: 2,
                    multiline: true,
                    inputComponent: "textarea",
                  },
                }}
              />
            )}

            <Typography
              variant="body2"
              color="text.primary"
              sx={{ fontWeight: "bold" }}
            >
              Answer
            </Typography>
            {code === "id" && (
              <Input
                props={{
                  placeholder: "Jawaban",
                  name: "answer",
                  value: v.answer,
                  onChange: (e) => {
                    handleChange(e.target.name, e.target.value, undefined, [
                      "children",
                      i,
                    ]);
                  },
                  InputProps: {
                    rows: 4,
                    multiline: true,
                    inputComponent: "textarea",
                  },
                }}
              />
            )}
            {code === "en" && (
              <Input
                props={{
                  placeholder: "Answer",
                  name: "answerEn",
                  value: v.answerEn,
                  onChange: (e) => {
                    handleChange(e.target.name, e.target.value, undefined, [
                      "children",
                      i,
                    ]);
                  },
                  InputProps: {
                    rows: 4,
                    multiline: true,
                    inputComponent: "textarea",
                  },
                }}
              />
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ width: "100%" }}
            >
              <Button
                startIcon={<Delete />}
                color="error"
                sx={{ borderRadius: "0.5rem", width: "fit-content" }}
                onClick={() => {
                  handleNavigation("delete", ["children"], i);
                  setExpanded(false);
                }}
              >
                Hapus
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
// instrumen edit like input, button upload etc
const CustomTextField = styled(TextField)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    borderColor: "#f3333",
    borderWidth: "0.1px",
  },
  "&.Mui-focused": {
    borderColor: "#f0f8ff",
    borderWidth: "0.1px",
  },
  "&:hover fieldset": {
    borderColor: "#f0f8ff",
    borderWidth: "0.1px",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(() => ({
  "&.MuiCircularProgress-root": {
    height: "15px !important",
    width: "15px !important",
    m: "auto",
    color: "f3333",
  },
}));

export const Input = ({ props }) => {
  return (
    <CustomTextField
      {...props}
      variant="outlined"
      size="small"
      sx={{
        my: props.my ? props.my : 2,
        width: props.width ? props.width : "100%",
      }}
    />
  );
};

const Dropdown = ({ name, value, onChange, data, disabled, obj }) => {
  return (
    <FormControl
      disabled={disabled ? true : false}
      variant="outlined"
      hiddenLabel
      size="small"
      color="black"
      sx={{ my: 1 }}
    >
      <Select
        name={name}
        value={value}
        onChange={onChange}
        renderValue={(selected) => {
          const selectedItem = data.find(
            (item) => item.value === (selected || value)
          );
          return selectedItem ? selectedItem.key : "";
        }}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#ffff",
              },
            },
          },
        }} // notes rendervalue
      >
        {data.map((v) => (
          <MenuItem key={v.key} value={v.value}>
            {v.key}
          </MenuItem>
        ))}
        {/* <>
            {!obj ? (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ) : (
              <MenuItem key={v.key} value={v.value}>
                {v.key}
              </MenuItem>
            )}
          </> */}
      </Select>
    </FormControl>
  );
};

const ButtonUploadMedia = ({ props }) => {
  const theme = useTheme();
  return (
    <Button
      sx={{
        p: 1,
        border: "1px solid lightgrey",
        color: `${theme.palette.text.primary}`,
        fontWeight: "normal",
        borderRadius: "1rem",
        mb: 2,
        position: "relative",
        width: "fit-content",
      }}
    >
      <Typography variant="body2" sx={{ display: "flex" }}>
        {props.loading ? <CustomCircularProgress /> : "Upload"}
      </Typography>
      <input
        {...props}
        type="file"
        id={props.variant}
        accept={
          props.variant === "element3"
            ? "image/png, image/jpg, image/jpeg"
            : props.variant === "element2"
            ? "video/mp4"
            : ""
        }
        style={{
          opacity: 0,
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </Button>
  );
};
