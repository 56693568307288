import React from "react";
import FormfacadeEmbed from "@formfacade/embed-react";
import { connect } from "react-redux";

const EmbedFormCom = ({ urlEmbedForm }) => {
  return (
    <>
      {urlEmbedForm && (
        <FormfacadeEmbed
          formFacadeURL={urlEmbedForm}
          onSubmitForm={() => console.log("Form submitted")}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  urlEmbedForm: state.preview.urlEmbedForm,
});

export default connect(mapStateToProps)(EmbedFormCom);
