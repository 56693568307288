import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Controls from "./controls";
import Preview from "./preview";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { domain } from "src/components/Layout/dummy";
import localforage from "localforage";
import {
  setPagePreview,
  setThemePreview,
  updateInfoInstitution,
  setBackdrop,
  customPageAPI,
  setNavChanged,
  getInfoInstitution,
} from "src/redux/actions";
import { compareArrayOfObjects } from "src/functions/compareArrayOfObjects";
import { version } from "src/configs/globalVariable";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    width: "450px",
  },
}));

const Index = ({ themePreview, backdrop, navChanged, pagePreview }) => {
  // Notes: dibawah 1200 harus munculin modal "tidak bisa diakses selain layar laptop keatas"
  const [data, setData] = useState([]);
  const [rowObjHover, setRowObjHover] = useState({});
  const [rowObjEdit, setRowObjEdit] = useState({});
  const [stateDrawer, setStateDrawer] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [token, setToken] = useState("");
  const [loadingDeleteNav, setLoadingDeleteNav] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const resToken = await localforage.getItem("token");
      const resData = await localforage.getItem("data");
      const resTheme = await localforage.getItem("theme");
      setToken(resToken);
      setData(data.length > 0 ? data : resData);
      setThemePreview(themePreview || resTheme);
    };
    fetchData();
    setNavChanged(false);
  }, [navChanged]); // data

  const handleChangePage = async (page) => {
    setBackdrop(true);
    let newData = [];
    const pageUrl = page.url;
    const homeData = await localforage.getItem("data");

    if (pageUrl === `/${version}/user`) {
      setPagePreview(page.url);
      newData = homeData;
    } else if (pageUrl === `/${version}/user/FAQ`) {
      setPagePreview(page.url);
      let faqData = await localforage.getItem("faq");
      // navigation web in custom page = nav web in home
      let headerHome = { ...homeData[0] };
      let headerFAQ = { ...faqData[0] };
      const headerChildrenHome = headerHome.children.slice(-1);
      const headerChildrenFAQ = headerFAQ.children.slice(-1);
      const compareHeader = compareArrayOfObjects(
        headerChildrenFAQ,
        headerChildrenHome
      );
      // for compare between children (contain navigation website)
      if (!compareHeader) {
        headerFAQ = { ...headerFAQ, children: headerChildrenHome };
        faqData[0] = headerFAQ;

        const footerFAQ = homeData.slice(-1)[0];
        faqData[faqData.length - 1] = footerFAQ;
        handleUpdateData(faqData, page.url);
      }
      newData = faqData;
    } else {
      setPagePreview(page);
      let type, landingContentType, id;
      if (page.id) {
        type = "content";
        landingContentType = "landing_content_id";
        id = page.id;
      } else {
        type = "group";
        landingContentType = "landing_content_group_id";
        id = page.idGroup;
      }

      const resData = await customPageAPI("get", type, {
        domain: domain,
        [`${landingContentType}`]: id,
      });
      const tempData = resData.data[0].content;
      // navigation web in custom page = nav web in home
      if (resData.data.length > 0) {
        let headerHome = { ...homeData[0] };
        let headerCustom = { ...tempData[0] };
        const headerChildrenHome = headerHome.children.slice(-1);
        const headerChildrenCustom = headerCustom.children.slice(-1);

        const compareHeader = compareArrayOfObjects(
          headerChildrenCustom,
          headerChildrenHome
        );
        // for compare between children (contain navigation website)
        if (!compareHeader) {
          headerCustom = { ...headerCustom, children: headerChildrenHome };
          tempData[0] = headerCustom;

          const footerCustom = homeData.slice(-1)[0];
          tempData[tempData.length - 1] = footerCustom;
          handleUpdateData(tempData, page);
        }
      }
      newData = tempData;
    }
    setData(newData);
    setBackdrop(false);
  };

  const handleVisibility = async (idx) => {
    const copyData = [...data];
    data[idx].visibility = !data[idx].visibility;

    handleUpdateData(copyData);
    setData(copyData);
  };

  const handleDelete = async () => {
    const copyData = [...data];
    if (openConfirm.idxCol === undefined) {
      copyData.splice(openConfirm?.idx, 1);
    } else {
      const copyDataRow = copyData[openConfirm?.idx];
      const copyDataCol = copyDataRow.children;
      copyDataCol.splice(openConfirm?.idxCol, 1);
    }

    handleUpdateData(copyData);
    setData(copyData);
    setOpenConfirm(false);
    setStateDrawer("");
  };

  const handleDeleteNav = async () => {
    const nav = openConfirm.data;
    if (nav.id) {
      await customPageAPI("delete", "content", {
        landing_content_id: nav.id,
      });
    } else if (nav.idGroup) {
      await customPageAPI("delete", "group", {
        landing_content_group_id: nav.idGroup,
      });
    }
    setLoadingDeleteNav(false);
    // closed open confirm => slideDrawer after running handleNav
  };

  const handleUpdateData = async (newData, page) => {
    setBackdrop(true);
    const newPagePreview = page || pagePreview;
    const token = await localforage.getItem("token");

    const obj = { token, domain };
    let type, resData, landingContentType, id;
    if (newPagePreview === `/${version}/user`) {
      resData = await localforage.getItem("data");
      type = "content";
    } else if (newPagePreview === `/${version}/user/FAQ`) {
      resData = await localforage.getItem("faq");
      type = "faq";
    } else {
      if (newPagePreview.id) {
        type = "content";
        landingContentType = "landing_content_id";
        id = newPagePreview.id;
      } else {
        type = "group";
        landingContentType = "landing_content_group_id";
        id = newPagePreview.idGroup;
      }
      const res = await customPageAPI("get", type, {
        domain: domain,
        [`${landingContentType}`]: id,
      });
      resData = res.data[0].content;
    }

    const compareData = compareArrayOfObjects(newData, resData);
    if (!compareData) {
      if (typeof newPagePreview === "string") {
        const jsonData = JSON.stringify(newData);
        await updateInfoInstitution(type, {
          ...obj,
          [`${type}`]: jsonData,
        });
        getInfoInstitution();
      } else {
        await customPageAPI("update", type, {
          [`${landingContentType}`]: id,
          title: newPagePreview.text,
          content: newData,
        });
      }
    }
    setBackdrop(false);
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
      }}
    >
      <DndProvider backend={Backend}>
        <Controls
          data={data}
          setData={setData}
          rowObjEdit={rowObjEdit}
          setRowObjEdit={setRowObjEdit}
          rowObjHover={rowObjHover}
          stateDrawer={stateDrawer}
          setStateDrawer={setStateDrawer}
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          loadingDeleteNav={loadingDeleteNav}
          setLoadingDeleteNav={setLoadingDeleteNav}
          handleVisibility={handleVisibility}
          handleChangePage={handleChangePage}
        />
        <Preview
          data={data}
          setData={setData}
          rowObjHover={rowObjHover}
          setRowObjHover={setRowObjHover}
          stateDrawer={stateDrawer}
          setStateDrawer={setStateDrawer}
          rowObjEdit={rowObjEdit}
          setRowObjEdit={setRowObjEdit}
          setOpenConfirm={setOpenConfirm}
          handleUpdateData={handleUpdateData}
        />
      </DndProvider>
      <CustomDialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <DialogTitle>{"Confirm deletion"}</DialogTitle>
        <DialogContent>
          {`Are you sure you would like to delete this ${openConfirm?.type}? This action cannot be undone.`}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setOpenConfirm(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              openConfirm?.type === "navigation and page"
                ? handleDeleteNav()
                : handleDelete()
            }
          >
            Confirm
          </Button>
        </DialogActions>
      </CustomDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
        onClick={() => setBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  pagePreview: state.preview.pagePreview,
  themePreview: state.preview.themePreview,
  backdrop: state.preview.backdrop,
  navChanged: state.preview.navChanged,
});

export default connect(mapStateToProps)(Index);
