import React, { useState, useEffect } from "react";
import localforage from "localforage";
import { Grid } from "@mui/material";
import UserRow from "src/components/Layout/UserRow";
import { connect } from "react-redux";
import { insert } from "src/pages/admin/settingV3/helpers";
import "src/App.css";

const Index = ({ heightHeader }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resTempData = await localforage.getItem("tempData");
    const num = heightHeader?.split("rem")[0];
    tempDataContent.sectProps.pt = num * 3 + "rem";
    tempDataContent.sectProps.pb = num * 2 + "rem";
    const tempData = insert(resTempData, 1, tempDataContent);
    setData(tempData);
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  return (
    <Grid component="main" sx={{}}>
      {data
        .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  heightHeader: state.preview.heightHeader,
});

export default connect(mapStateToProps)(Index);

const tempDataContent = {
  id: "HRLvn446",
  type: "row",
  props: {
    maxWidth: "80%",
  },
  children: [
    {
      id: "TsUZ5NxC",
      type: "column",
      props: {},
      children: [
        {
          id: "jO06iszA",
          type: "element",
          variant: "element80", // embed form => shortcut : component in list elements library.js
        },
      ],
      colWidth: 12,
    },
  ],
  sectProps: {},
  visibility: true,
};
