import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import UserRow from "src/components/Layout/UserRow";
import localforage from "localforage";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { insert } from "src/pages/admin/settingV3/helpers";
import "src/App.css";
import { version } from "src/configs/globalVariable";
import { useLocation } from "react-router-dom";

const Index = ({ isAuthenticated, heightHeader }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    checkParamsUrl();
    fetchData();
  }, [location.pathname]);

  const checkParamsUrl = async () => {
    if (!isAuthenticated) {
      navigate(`/${version}/user/`);
      window.location.reload();
      return;
    }
  };

  const fetchData = async () => {
    const pathname = location.pathname;
    const segments = pathname.split("/");
    const lastSegment = segments.pop();

    let tempDataContent;
    if (lastSegment === "my-career") tempDataContent = tempDataContent1;
    else if (lastSegment === "my-wishlist") tempDataContent = tempDataContent2;
    
    const resTempData = await localforage.getItem("tempData");
    const num = heightHeader?.split("rem")[0];
    tempDataContent.sectProps.pt = num * 3 + "rem";
    tempDataContent.sectProps.pb = num * 2 + "rem";
    const tempData = insert(resTempData, 1, tempDataContent);
    setData(tempData);
  };

  const renderRow = (row) => {
    return <UserRow key={row.id} data={row} />;
  };

  return (
    <Grid
      component="main"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F6F8FD",
      }}
    >
      {data
        .filter((v) => v.visibility)
        .map((row) => {
          return <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment>;
        })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  heightHeader: state.preview.heightHeader,
});

export default connect(mapStateToProps)(Index);

const tempDataContent1 = {
  id: "vPmkG4fGM",
  type: "row",
  props: {
    maxWidth: "80%",
  },
  children: [
    {
      id: "31ZVfDnAV",
      type: "column",
      props: {},
      children: [
        {
          id: "Wa2MSTlSv",
          type: "element",
          variant: "element31", // my Career => shortcut : component in list elements library.js
        },
      ],
      colWidth: 12,
    },
  ],
  sectProps: {},
  visibility: true,
};

const tempDataContent2 = {
  id: "vPmkG4fYU",
  type: "row",
  props: {
    maxWidth: "95%",
  },
  children: [
    {
      id: "31ZVfDnAV",
      type: "column",
      props: {},
      children: [
        {
          id: "Wa2MSTlSv",
          type: "element",
          variant: "element32", // my wishlist => shortcut : component in list elements library.js
        },
      ],
      colWidth: 12,
    },
  ],
  sectProps: {},
  visibility: true,
};
