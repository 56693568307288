import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localforage from "localforage";
import { Stack, useMediaQuery, Grid, useTheme } from "@mui/material";
import { dataPub } from "src/components/Layout/dummy";
import { getPublish } from "src/redux/actions";
import CardPro02 from "src/components/Cards/CardPro02";

const ContainerCardWishlist = () => {
  const isScreen500 = useMediaQuery("(min-width:500px)");
  const isScreen600 = useMediaQuery("(min-width:600px)");
  const isScreen700 = useMediaQuery("(min-width:700px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1400 = useMediaQuery("(min-width:1400px)");
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const [userRole, setUserRole] = useState("");
  const [publish, setPublish] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // when load the data article
    const arrPublish = [{ ...dataPub }, { ...dataPub }, { ...dataPub }];
    setPublish(arrPublish);

    const resRole = await localforage.getItem("userRole");
    setUserRole(resRole);

    const token = await localforage.getItem("token");
    const data = await getPublish(token);
    if (data.data) {
      if (isScreen1536 && userRole !== "5")
        setPublish(data.data.length > 1 ? data.data.slice(0, 5) : data.data);
      else if (isScreen1200)
        setPublish(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      else if (isScreen700)
        setPublish(data.data.length > 1 ? data.data.slice(0, 3) : data.data);
      else if (isScreen500)
        setPublish(data.data.length > 1 ? data.data.slice(0, 2) : data.data);
      else if (!isScreen500)
        setPublish(data.data.length > 1 ? data.data.slice(0, 4) : data.data);
      setLoading(false);
    }
  };

  return (
    <Grid container component="section" className="slide-in-left">
      <Grid
        item
        container
        sx={{
          maxWidth:
            userRole === "5"
              ? "500px"
              : isScreen2000
              ? "1536px"
              : isScreen1700
              ? "1440px"
              : isScreen1400
              ? "1200px"
              : isScreen1200
              ? "1024px"
              : "900px",
          m: "0 auto",
          p: 2,
          pl: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          pr: userRole === "5" ? 2 : isScreen700 ? 3 : 0,
          pb: userRole === "5" ? 0 : 2,
          pt: userRole === "5" ? 0 : 2,
        }}
      >
        {!isScreen700 && (
          <Stack
            direction="row"
            spacing={isScreen600 ? 3 : 2}
            sx={{
              pt: 2,
              pb: 1,
              overflowX: "auto",
              width: "100%",
            }}
          >
            {publish.map((v, i) => {
              return (
                <CardPro02
                  v={v}
                  key={i}
                  i={i}
                  z={publish.length - 1}
                  loading={loading}
                />
              );
            })}
          </Stack>
        )}
        {isScreen700 && (
          <Grid
            item
            container
            spacing={
              userRole === "5" ? 2 : isScreen600 ? 3 : isScreen500 ? 2 : 3
            }
            sx={{
              pt: userRole === "5" ? "0.9rem" : "1rem",
              // mb: userRole === "5" ? 0 : 1,
            }}
          >
            {publish.map((v, i) => (
              <CardPro02 v={v} key={i} userRole={userRole} loading={loading} />
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ContainerCardWishlist;
