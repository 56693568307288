import axios from "axios";
import { setDeauthenticated, setSnackbarOpen } from "src/redux/actions";
import localforage from "localforage";
import { version } from "src/configs/globalVariable";

const setAPI = async () => {
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV !== "production"
        ? "http://localhost:5000/api_colagio"
        : window.location.origin + "/api_colagio",
    // "pesantrenkoding.com"
    // "https://dev-veera.shratech.com/api_colagio"
    // "http://localhost:5000/api_colagio"
    // baseURL: "https://dev-veera.shratech.com/api_colagio",
    // baseURL: "https://pesantrenkoding.com/api_colagio",
    // baseURL: "https://sharp.shratech.com/api_colagio",
    // baseUrl: "https://colagio.shratech.com/api_colagio",
  });

  instance.interceptors.request.use(async (config) => {
    config.headers["x-access-token"] = await localforage.getItem("token");
    if (!config.headers["User-Agent"]) {
      config.headers["User-Agent"] = "recruitment";
    }

    // Jika header Referer kosong, tambahkan nilai default
    if (!config.headers["Referer"]) {
      config.headers["Referer"] = "https://career.pamapersada.com";
    }
    return config;
  });

  instance.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
      return config;
    } else {
      if (config.data === undefined) {
        return config;
      } else {
        config.headers["x-access-token"] = config.data.token;
        if (!config.headers["User-Agent"]) {
          config.headers["User-Agent"] = "recruitment";
        }

        // Jika header Referer kosong, tambahkan nilai default
        if (!config.headers["Referer"]) {
          config.headers["Referer"] = "https://career.pamapersada.com";
        }
        return config;
      }
    }
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        setDeauthenticated();
        setSnackbarOpen("error", "Session Ands sudah habis");
        setTimeout(() => {
          window.location.replace(`/${version}/user/`);
        }, 1000);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default setAPI;
