import React, { useState, useEffect } from "react";
import { listElements } from "../../pages/admin/settingV3/library";
import {
  Stack,
  Grid,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import {
  Instagram,
  Language,
  LinkedIn,
  PhoneEnabled,
  WhatsApp,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { useTranslation } from "react-i18next";
import "src/App.css";

const BasicFooter = ({ data }) => {
  const isScreen768 = useMediaQuery("(min-width:768px)");
  const theme = useTheme();
  const navigate = useNavigate();
  const [bio, setBio] = useState({});
  const [userRole, setUserRole] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    const resRole = await localforage.getItem("userRole");
    const resBio = await localforage.getItem("bio");
    setBio(resBio);
    setUserRole(resRole);
  };

  const filterIcon = (media) => {
    switch (media) {
      case "Instagram":
        return <Instagram />;
      case "LinkedIn":
        return <LinkedIn />;
      case "WhatsApp":
        return <WhatsApp />;
      case "Browser":
        return <Language />;
      case "Telephone":
        return <PhoneEnabled />;
      default:
        break;
    }
  };

  const navFilter = (url) => {
    if (url && url.indexOf("user") !== -1) navigate(url);
    else {
      if (url) {
        const needHttp = url.indexOf("http") === -1 ? "https://" : "";
        window.location.replace(`${needHttp}${url}`);
      }
    }

    // if (url.indexOf(bio.domain) !== -1) {
    //   let link;
    //   if (bio.domain === "localhost") link = url.split(bio.domain + ":3000");
    //   else link = url.split(bio.domain);
    //   navigate(link[1]);
    // } else {
    //   window.location.replace(url);
    // }
  };

  const enabledDisplay = () => {
    if (!isScreen768) return true;
    else return false;
  };

  return (
    <Grid
      item
      container
      component="footer"
      className={data.elCn && `el ${data.elCn}`}
      sx={{
        // position:
        //   window.location.pathname.indexOf("user") !== -1
        //     ? "absolute"
        //     : "unset",
        // bottom: 0,
        // left: 0,
        // right: 0,
        transition: "all linear .2s",
        color: `${theme.palette.text.primary}`,
        borderTop: "0.1px solid lightgrey",
        position: "relative",
        px: 3,
      }}
    >
      {data.elCn === "footer" && (
        <>
          {data.children.map((v, i) => (
            <React.Fragment key={i}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  width: "100%",
                  p: isScreen768 && 2,
                  color: "inherit",
                }}
              >
                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {listElements(
                    v,
                    undefined,
                    undefined,
                    undefined,
                    true && !isScreen768
                  )}
                </Grid>
                {!enabledDisplay() && (
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {v.link.length > 0 &&
                      v.link
                        .filter((link) => link.text && link.visibility)
                        .map((link, x) => (
                          <Typography
                            variant="body2"
                            color="inherit"
                            key={x}
                            sx={{
                              m: 2,
                              transition: "all linear .2s",
                              "&:hover": {
                                cursor: "pointer",
                                transform: "scale(1.25)",
                              },
                            }}
                            onClick={() => navFilter(link.url)}
                          >
                            {/* {link.text} */}
                            {i18n.language === "en" ? link.textEn : link.text}
                          </Typography>
                        ))}
                  </Grid>
                )}
              </Stack>
              <Stack
                direction="column"
                sx={{
                  width: "100%",
                  p: isScreen768 && 2,
                  pb: isScreen768 ? 3 : 2,
                  color: "inherit",
                  borderBottom: "0.1px solid lightgrey",
                }}
              >
                {v.mainLink.length > 0 &&
                  v.mainLink
                    .filter((link) => link.text && link.visibility)
                    .map((link, x) => (
                      <Typography
                        variant="body1"
                        color="inherit"
                        key={x}
                        sx={{
                          my: 1.25,
                          transition: "all linear .2s",
                          "&:hover": {
                            cursor: "pointer",
                            color: "rgba(0,0,0,0.5)",
                          },
                        }}
                        onClick={() => navFilter(link.url)}
                      >
                        {/* {link.text} */}
                        {i18n.language === "en" ? link.textEn : link.text}
                      </Typography>
                    ))}
              </Stack>
              {enabledDisplay() &&
                v.link.length > 0 &&
                v.link.filter((link) => link.text && link.visibility).length !==
                  0 && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      borderBottom: "1px solid lightgrey",
                      py: 1.5,
                      mb: 3,
                      overflowX: "auto",
                      minWidth: "100%",
                    }}
                  >
                    {v.link
                      .filter((link) => link.text && link.visibility)
                      .map((link, x) => (
                        <Grid key={x} item xs="auto" sx={{ p: 1 }}>
                          <Typography
                            component="a"
                            variant="body2"
                            sx={{ m: 1 }}
                          >
                            {/* {link.text} */}
                            {i18n.language === "en" ? link.textEn : link.text}
                          </Typography>
                        </Grid>
                      ))}
                  </Stack>
                )}
              <Stack
                direction={isScreen768 ? "row" : "column"}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  p: isScreen768 && 2,
                  pt: v.link.length === 0 && 3.25,
                  color: "inherit",
                }}
              >
                {(isScreen768 ||
                  v.address.toLowerCase().indexOf("copyright") === -1) && (
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="inherit"
                      sx={{ textAlign: !isScreen768 && "center" }}
                    >
                      {v?.address}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  lg={6}
                  md={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: isScreen768 ? "flex-end" : "center",
                  }}
                >
                  {v?.socialMedia?.map((link, x) => (
                    <>
                      {link.media === "Floating WhatsApp Button" &&
                      link.visibility ? (
                        <Stack
                          sx={{
                            position: userRole === "5" ? "absolute" : "fixed",
                            right: isScreen768 ? 50 : 20,
                            bottom: 75,
                            color: "#ffff",
                            backgroundColor: "#128C7E",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                            p: 1,
                            borderRadius: "1.5rem",
                            zIndex: 101,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              cursor: "pointer",
                              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                              transform: "scale(1.1)",
                            },
                          }}
                          onClick={() => window.location.replace(link.content)}
                        >
                          <IconButton
                            sx={{
                              width: "40px",
                              height: "40px",
                              color: "#ffff",
                            }}
                          >
                            {filterIcon("WhatsApp")}
                          </IconButton>
                          <Typography variant="body2" color="inherit">
                            {link.text}
                          </Typography>
                        </Stack>
                      ) : link.media !== "Floating WhatsApp Button" ? (
                        <Tooltip
                          key={x}
                          title={link.content}
                          arrow
                          placement="bottom-start"
                        >
                          <IconButton
                            sx={{
                              my:
                                !isScreen768 &&
                                v.address.toLowerCase().indexOf("copyright") !==
                                  -1
                                  ? null
                                  : 2,
                              width: "40px",
                              height: "40px",
                            }}
                            onClick={() =>
                              link.media === "Telephone"
                                ? null
                                : window.location.replace(link.content)
                            }
                          >
                            {filterIcon(link.media)}
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </>
                  ))}
                </Grid>

                {!isScreen768 &&
                  v.address.toLowerCase().indexOf("copyright") !== -1 && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        my: 2,
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="inherit"
                        sx={{ textAlign: !isScreen768 && "center" }}
                      >
                        {v?.address}
                      </Typography>
                    </Grid>
                  )}
              </Stack>
            </React.Fragment>
          ))}
        </>
      )}
    </Grid>
  );
};

export default BasicFooter;
