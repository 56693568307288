import { ELEMENT, ROW, Row, Column } from "./library";
import { nanoid } from "nanoid";
// import { version } from "src/configs/globalVariable";

function generateNewIds(obj) {
  // Create a deep copy
  const newObj = JSON.parse(JSON.stringify(obj));
  function updateIds(item) {
    if (item.id) {
      item.id = nanoid(8);
    }
    if (item.children && Array.isArray(item.children)) {
      item.children.forEach((child) => updateIds(child));
    }
  }

  updateIds(newObj);
  return newObj;
}

export const handleItemIntoParent = (layout, splitDropZonePath, item) => {
  let changedItemId = generateNewIds(item); // changed id
  let newItem = { ...changedItemId, type: ELEMENT };
  delete newItem.content;
  delete newItem.variantText;
  let newLayoutStructure;

  switch (splitDropZonePath.length) {
    case 1: {
      if (item.variant.indexOf("section") !== -1) {
        newLayoutStructure = {
          ...newItem,
          type: ROW,
        };
        delete newLayoutStructure.variant;
      } else {
        newLayoutStructure = {
          ...Row,
          id: nanoid(8),
          visibility: true,
          children: [
            {
              ...Column,
              colWidth: Column.colWidth - 1,
              id: nanoid(8),
              children: [newItem],
            },
          ],
        };
      }
      break;
    }
    case 2: {
      const curIndex = Number(splitDropZonePath.slice(0, 1));
      const length = layout[curIndex].children.length;
      layout[curIndex].children.forEach(
        (v) => (v.colWidth = 12 / (length + 1))
      );

      newLayoutStructure = {
        ...Column,
        id: nanoid(8),
        colWidth: 12 / (length + 1),
        children: [newItem],
      };
      break;
    }
    default: {
      newLayoutStructure = newItem;
      break;
    }
  }
  return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};

export const addChildToChildren = (children, splitDropZonePath, item) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(children, dropZoneIndex, item);
  }
  const updatedChildren = [...children];
  const curIndex = Number(splitDropZonePath.slice(0, 1));
  const splitItemChildrenPath = splitDropZonePath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(
      nodeChildren.children,
      splitItemChildrenPath,
      item
    ),
  };
  return updatedChildren;
};

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const handleMoveWithinParent = (
  layout,
  splitDropZonePath,
  splitItemPath
) => {
  return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }
  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);

  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: reorderChildren(
      nodeChildren.children,
      splitDropZoneChildrenPath,
      splitItemChildrenPath
    ),
  };
  return updatedChildren;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list); // copy arr layout
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  // inserting task in new index
  return result;
};

// const faq = [
//   {
//     id: "F3WiHXxabol",
//     type: "row",
//     props: {},
//     rowCn: "root-stack",
//     sectCn: "root-stack",
//     children: [
//       {
//         id: "k3G10ft0Bxj",
//         type: "column",
//         colCn: "header",
//         props: {},
//         children: [
//           {
//             id: "6YrkejRWuTZ",
//             elCn: "triangle-header",
//             type: "element",
//             variant: "element11",
//             children: [
//               {
//                 id: "Tpe7CAzmTlN",
//                 src: "assets/files/lohx4ftqtb8-1698973781534.png",
//                 link: [
//                   {
//                     url: `/${version}/user/FAQ`,
//                     text: "FAQ",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                 ],
//                 type: "element",
//                 props: {
//                   height: "3.5rem",
//                 },
//                 action: "login",
//                 variant: "element3",
//                 mainLink: [
//                   {
//                     url: "/${version}/user",
//                     text: "Beranda",
//                     textEn: "Home",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     text: "Hidup & Kerja",
//                     textEn: "Life & Work",
//                     idGroup: 1700551945697,
//                     isGroup: true,
//                     children: [
//                       {
//                         id: "wXfVLZ7DG",
//                         text: "Hidup & Bekerja di PAMA",
//                         textEn: "Live & Work at PAMA",
//                         visibility: true,
//                       },
//                       {
//                         id: "R85iovPXP",
//                         text: "Learning & Development",
//                         textEn: "",
//                         visibility: true,
//                       },
//                       {
//                         id: "rJRtbQyRu",
//                         text: "Career & Work Life Balance",
//                         textEn: "",
//                         visibility: true,
//                       },
//                       {
//                         id: "BoGLHfrDu",
//                         text: "Our People",
//                         textEn: "",
//                         visibility: true,
//                       },
//                     ],
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     text: "Our Value",
//                     textEn: "",
//                     idGroup: 1700559886158,
//                     isGroup: true,
//                     children: [
//                       {
//                         id: "wZ5mOjT0e",
//                         text: "06 Core Values",
//                         textEn: "",
//                         visibility: true,
//                       },
//                       {
//                         id: "iKrr88Vcr",
//                         text: "Champion Spirit Character",
//                         textEn: "",
//                         visibility: true,
//                       },
//                     ],
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url:`/${version}/user/vacancy`,
//                     text: "Vacancy",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     text: "Functions",
//                     textEn: "",
//                     idGroup: 1701403935664,
//                     isGroup: true,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                 ],
//                 dashboard: [
//                   {
//                     url: `/${version}/user/dashboard/my-career`,
//                     text: "My Career",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url: `/${version}/user/profile`,
//                     text: "My Profile",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//         colWidth: 12,
//       },
//     ],
//     sectProps: {
//       aspectRatio: "4/1",
//       backgroundImage:
//         "https://bankmandiri.co.id/documents/20143/44646000/ag-karir-faq-hero.png/68a78c9d-33c3-4415-271a-4f9f31bdd80e?t=1624260217728",
//     },
//     visibility: true,
//   },
//   {
//     id: "PPMdmSrNC",
//     type: "row",
//     props: {
//       maxWidth: "90%",
//     },
//     children: [
//       {
//         id: "kOnGdCd19W",
//         type: "column",
//         props: {},
//         children: [
//           {
//             id: "86N0Ctc1cS",
//             type: "element",
//             variant: "element61",
//             children: [
//               {
//                 answer:
//                   "All recruitment and selection activities during the Covid-19 Pandemic follow the health protocols that have been set and the process is carried out virtually / online, except for the health screening tests are carried out offline while still implementing the health protocol.",
//                 answerEn: "",
//                 question:
//                   "How is the recruitment process carried out during the Covid-19 pandemic?",
//                 questionEn: "",
//               },
//               {
//                 answer: "test",
//                 answerEn: "",
//                 question: "test?",
//                 questionEn: "",
//               },
//             ],
//           },
//         ],
//         colWidth: 12,
//       },
//     ],
//     sectProps: {},
//     visibility: false,
//   },
//   {
//     id: "W1mteQm86",
//     type: "row",
//     props: {
//       maxWidth: "90%",
//     },
//     sectCn: "footer",
//     children: [
//       {
//         id: "31ZVfDnAV",
//         type: "column",
//         colCn: "footer",
//         props: {},
//         children: [
//           {
//             id: "Wa2MSTlSv",
//             elCn: "footer",
//             type: "element",
//             variant: "element12",
//             children: [
//               {
//                 id: "Hzp6aXqCs",
//                 src: "assets/files/lohxp7l7n5f-1698974750635.png",
//                 link: [
//                   {
//                     url: `/${version}/user`,
//                     text: "Home",
//                     tetEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url: `/${version}/user/vacancy`,
//                     text: "Vacancy",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url: `/${version}/user/FAQ`,
//                     text: "FAQ",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                 ],
//                 type: "element",
//                 props: {
//                   height: "3.5rem",
//                 },
//                 address:
//                   "Jl. Rawagelam 1 No. 9 Kawasan Industri Pulogadung Jakarta Timur, 13930",
//                 variant: "element3",
//                 mainLink: [
//                   {
//                     url: `/${version}/user`,
//                     text: "Home",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url: `/${version}/user/vacancy`,
//                     text: "Vacancy",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                   {
//                     url: `/${version}/user/FAQ`,
//                     text: "FAQ",
//                     textEn: "",
//                     isGroup: false,
//                     isExternal: false,
//                     visibility: true,
//                   },
//                 ],
//                 socialMedia: [
//                   {
//                     media: "Browser",
//                     content: "https://www.pamapersada.com",
//                   },
//                   {
//                     media: "Telephone",
//                     content: "(021) 4602015 Ext. 2127",
//                   },
//                   {
//                     media: "Instagram",
//                     content: "https://www.instagram.com/join_pamapersada/",
//                   },
//                   {
//                     media: "LinkedIn",
//                     content:
//                       "https://id.linkedin.com/company/pamapersada-nusantara",
//                   },
//                   {
//                     media: "WhatsApp",
//                     content: "https://wa.me/6285172194529",
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//         colWidth: 12,
//       },
//     ],
//     sectProps: {},
//     visibility: true,
//   },
// ];
