import React, { useRef, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { listElements } from "../library";
import { IconButton } from "@mui/material";
import { ELEMENT } from "../library";
import { useNavigate } from "react-router-dom";
import "../../../../App.css";
import localforage from "localforage";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { loadImage } from "src/functions/utils";

const iconBtnStyle = {
  display: "none",
  position: "absolute",
  top: 0,
  p: 0.5,
  zIndex: 998,
  backgroundColor: "#f0f8ff",
  width: "40px",
  height: "40px",
  boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
  "&:hover": {
    backgroundColor: "#f0f8ff",
  },
};

const Element = ({
  data,
  colId,
  rowId,
  rowObjHover,
  setRowObjHover,
  setStateDrawer,
  setRowObjEdit,
  path,
  setOpenConfirm,
}) => {
  const [bio, setBio] = useState({});
  const navigate = useNavigate();
  const [src, setSrc] = useState("");
  const [poster, setPoster] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    if (data?.variant === "element2") { // video
      const resNewGet = await loadImage(data.src);
      const resPoster = await loadImage(data.poster);
      console.log(data.src, resNewGet);
      setSrc(resNewGet);
      setPoster(resPoster);
    }
  }, []);

  const fetchData = async () => {
    const resBio = await localforage.getItem("bio");
    setBio(resBio);
  };

  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ELEMENT,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const navFilter = (url) => {
    navigate(url);
    // if (url.indexOf(bio.domain) !== -1) {
    //   let link;
    //   if (bio.domain === "localhost") link = url.split(bio.domain + ":3000");
    //   else link = url.split(bio.domain);
    //   navigate(link[1]);
    // } else {
    //   window.location.replace(data.url);
    // }
  };

  return (
    <div
      ref={drag(ref)}
      style={{
        display: data.variant === "element0" && "block",
        position: rowObjHover?.elId === data.id && "relative",
        width:
          data?.variant === "element1" ||
          data?.variant === "element2" ||
          data?.variant === "element3" ||
          data?.variant === "element11" ||
          data?.variant === "element12" ||
          data?.variant === "element61"
            ? "100%"
            : null,
        opacity: isDragging ? 0 : 1,
      }}
      className="container"
      onMouseEnter={() => {
        setRowObjHover({ rowId, colId, elId: data.id });
      }}
      // onClick={() => {
      //   // setRowObjHover({ rowId, colId, elId: data.id });
      //   if (data?.url) {
      //     navFilter(data?.url);
      //   } else {
      //     return null;
      //   }
      // }}
    >
      {rowObjHover?.colId && (
        <>
          {/* <IconButton
            sx={{ ...iconBtnStyle, right: "55px" }}
            className="btnHoverEdit"
            onClick={() => {
              setOpenConfirm("element")
              setStateDrawer("");
            }}
          >
            <Delete sx={{ color: "#D32F2F" }} />
          </IconButton> */}
          <IconButton
            sx={{ ...iconBtnStyle, left: "0px", zIndex: 998 }}
            className="btnHoverEdit"
            onClick={() => {
              setRowObjEdit({
                rowId,
                colId,
                elId: data.id,
                elVariant: data.variant,
              });
              setStateDrawer("");
              setTimeout(() => {
                setStateDrawer("com");
              }, 450);
            }}
          >
            {/* <i class="fi fi-sr-pencil"></i> */}
            <Edit />
          </IconButton>
        </>
      )}
      {listElements(data, undefined, src, poster)}
    </div>
  );
};

export default Element;
